import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getNotifications,
  onNotificationAction,
  setNotifications,
} from "../../../actions/notificationActions";
import { Notification as NotificationWrapper } from "impact-ui-v3";

const Notification = (props) => {
  const [notificationsData, setNotificationsData] = useState([]);

  useEffect(() => {
    setNotificationsData(props.notificationData);
  }, [props.notificationData]);

  // Generic function to update notification statuses
  const updateNotificationStatus = useCallback(
    (type, notificationList, updates) => {
      const updatedList = notificationList.map((item) => ({
        ...item,
        ...updates,
      }));
      setNotificationsData((prevData) => ({
        ...prevData,
        [type]: updatedList,
      }));
    },
    []
  );

  // Specific handlers using the generic updateNotificationStatus function
  const handleSelectAll = useCallback(
    (type, notificationList) => {
      updateNotificationStatus(type, notificationList, { selected: true });
    },
    [updateNotificationStatus]
  );

  const handleMarkReadAll = useCallback(
    (type, notificationList) => {
      updateNotificationStatus(type, notificationList, { read: true });
    },
    [updateNotificationStatus]
  );

  const handleMoveAllPending = useCallback(
    (type, notificationList) => {
      updateNotificationStatus(type, notificationList, {
        read: false,
        selected: false,
      });
    },
    [updateNotificationStatus]
  );

  const handleNotificationDeleteAll = useCallback((type) => {
    setNotificationsData((prevData) => ({ ...prevData, [type]: [] }));
  }, []);

  return (
    <>
      <NotificationWrapper
        anchor="right"
        isOpen={props?.open}
        badgesList={[
          {
            id: 1,
            lists: [
              {
                handleClick: () => {},
                id: 1,
                label: "New"
              },
              {
                handleClick: () => {},
                id: 2,
                label: "Pending"
              },
              {
                handleClick: () => {},
                id: 3,
                label: "Completed",
              },
              {
                handleClick: () => {},
                id: 4,
                label: "Bookmarked",
              },
              {
                handleClick: () => {},
                id: 5,
                label: "Archived",
              },
            ],
            value: "task_list",
          },
          {
            id: 2,
            lists: [
              {
                id: 1,
                label: "Recent",
                onClick: () => {},
              },
              {
                id: 2,
                label: "Old",
                onClick: () => {},
              },
              {
                id: 3,
                label: "Archive",
                onClick: () => {},
              },
              {
                id: 4,
                label: "All",
                onClick: () => {},
              },
            ],
            value: "info",
          },
        ]}
        handleClose={() => {
          props?.setOpen(false);
        }}
        handleMarkReadAll={handleMarkReadAll}
        handleMoveAllPending={handleMoveAllPending}
        handleNotificationDeleteAll={handleNotificationDeleteAll}
        handleSelectAll={handleSelectAll}
        notificationPanels={[
          {
            handleSettingClick: () => {},
            id: 1,
            notificationList: notificationsData?.task_list,
            value: "task_list",
          },
          {
            handleSettingClick: () => {},
            id: 2,
            notificationList: notificationsData?.info,
            value: "info",
          },
        ]}
        notificationTabs={[
          {
            label: "Task List",
            value: "task_list",
          },
          {
            label: "Info",
            value: "info",
          },
        ]}
        onPrimaryButtonClick={() => {}}
        onSecondaryButtonClick={() => {}}
        onSettingButtonClick={() => {}}
        primaryButtonLabel="Save"
        secondaryButtonLabel="Clear all"
        setIsOpen={props?.setOpen}
        setNotificationPanels={() => {}}
        title="Notification"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userNotificationTypes: state.notificationReducer.userNotificationTypes,
    notificationData: state.notificationReducer.notificationData,
    notificationCount: state.notificationReducer.notificationCount,
    dnd: state.notificationReducer.dnd,
    websocket: state.notificationReducer.wsClient,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    getNotification: (pageIndex, pageSize, filterOptions) =>
      dispatch(getNotifications(pageIndex, pageSize, filterOptions)),
    performAction: (notification, action) =>
      dispatch(onNotificationAction(notification, action)),
    setNotifications: (notifications) =>
      dispatch(setNotifications(notifications)),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(Notification);
