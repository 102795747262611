import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { logoutUser } from "../../actions/authActions";
import { connect, useDispatch } from "react-redux";
import { Sidebar as SidebarRenderer } from "impact-ui-v3";

const SideBar = ({ options, props }) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState();
  const [childActive, setchildActive] = useState();

  const lastUrlSegment = useMemo(() => window.location.pathname.split("/").pop(), [window.location.pathname]);

  const findActiveRoute = (options, lastUrlSegment) => {
    let activeRoute = null;
    options.forEach((item) => {
      if (item.link.split("/").pop() === lastUrlSegment) {
        activeRoute = { active: item.value, childActive: null };
      } else if (item.isParent) {
        item.children?.forEach((childItem) => {
          if (childItem.link.split("/").pop() === lastUrlSegment) {
            activeRoute = { active: item.value, childActive: childItem.value };
          }
        });
      }
    });
    return activeRoute;
  };

  useEffect(() => {
    if (!options) return;
    const activeRoute = findActiveRoute(options, lastUrlSegment);
    if (activeRoute) {
      setActive(activeRoute.active);
      setchildActive(activeRoute.childActive);
    }
  }, [options, lastUrlSegment]);


  const logoutCurrentUser = () => {
    Cookies.remove("authToken", {
      domain:
        window.location.hostname === "localhost"
          ? "localhost"
          : "impactsmartsuite.com",
    });
    localStorage.removeItem("name");

    history.push("/login", {
      state: { client: window?.location?.pathname },
    });
  };

  const handleParentRouteChange = (item) => {
    if (item?.isParent) {
      setActive(item.value);
      setchildActive(item.children[0]?.value);
      history.push(item.children[0]?.link);
    } else {
      setActive(item.value);
      setchildActive(null);
  
      if (location.pathname === item.link) {
        props.setActiveScreenName(null);
        dispatch({
          type: "REFRESH_ROUTE",
          payload: item.link,
        });
      } else {
        history.push(item.link);
      }
     
    }
  };
  

  return (
    <div>
      <SidebarRenderer
        isOpen={open}
        setIsOpen={setOpen}
        handleClose={() => setOpen(!open)}
        routes={options}
        parentActive={active}
        childActive={childActive}
        handleChildRouteChange={(parent, child) => {
          setchildActive(child.value);
          history.push(child.link);
        }}
        handleParentRouteChange={(item) => {
          handleParentRouteChange(item);
        }}
        handleLogOut={logoutCurrentUser}
        isCloseWhenClickOutside
      />
    </div>
  );
};

SideBar.defaultProps = {
  options: [],
  pathPrefix: "",
};

SideBar.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      link: PropTypes.string,
      isParent: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    })
  ),
  pathPrefix: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
};

export default connect(null, { logoutUser })(withRouter(SideBar));

